(function($, undefined) {
	'use strict';
	// TODO: コメントちゃんと書く
	var namespace = 'createLocalNavDepth';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selectors: {
					breadcrumb: '[data-js-gs18-breadcrumb]'
				}
			},
			options
		);

		var init,
			createNode,
			createChildren,
			createBreadcrumb,
			depth = 1;

		var $node = $('<ul />').addClass([
			'gs18-LocalNavList',
			'gs18-LocalNavList--Root'
		]);

		init = function($elem) {
			// json が指定されていなければローカルナビゲーションを非表示にし、処理を終了する
			if (!settings.local_nav_json_path) return !!$elem.hide();

			$.ajax({
				type: 'GET',
				url: settings.local_nav_json_path
			})
				.done(function(data) {
					var $children = createNode(data);
					var $current = $children.find('.is-current');
					var $sibling = $current.siblings();
					var $parents = $current.parents('li');

					$sibling.addClass('is-sibling');
					$parents.addClass('is-current');
					$elem.append($children);

					createBreadcrumb($children);
					settings.callback();
				})
				.fail(function() {
					console.warn(arguments);
				});
		};

		createNode = function(data) {
			return $node.append(data.map(createChildren));
		};

		createChildren = function(data) {
			depth++; // 表示は5階層までなので深度のカウントが必要、CSSで表示を制御する

			var $ul = $('<ul />').addClass([
				'gs18-LocalNavList',
				'gs18-LocalNavList--Depth' + depth
			]);
			var $li = $('<li />').addClass('gs18-LocalNavItem');
			var $item = data.url ? $('<a />').attr('href', data.url) : $('<span />');

			$item.text(data.title);
			$li.append($item);

			if (data.children && data.children.length) {
				$ul.append(data.children.map(createChildren));
				$li.append($ul);
			}

			if ($ul.children('.is-current').length) {
				$ul.addClass('gs18-LocalNavList--Last');
			}

			if (data.url === window.MEL_SETTINGS.current_path) {
				$li.addClass('is-current');
			}

			depth--;
			return $li;
		};

		// パンくずを生成する
		createBreadcrumb = function($children) {
			var $breadcrumb = $(settings.selectors.breadcrumb);
			if (!$breadcrumb.length) return;

			var $list = $('<ul class="gs18-BreadcrumbList"></ul>');
			$list.append(
				'<li class="gs18-BreadcrumbItem"><a href="/en/">Home</a></li>'
			);

			$children
				.find('.is-current')
				.find('> a, > span')
				.each(function(elem) {
					var $item = $(this);
					var $li = $('<li class="gs18-BreadcrumbItem" />');
					var $child = $item.is('span')
						? $('<a />')
						: $('<a />').attr('href', $item.attr('href'));

					$child.text($item.text());
					$list.append($li.append($child));
				});

			$list.find('li:last a').attr({
				href: null,
				'aria-current': 'page'
			});

			$breadcrumb.append($list);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
