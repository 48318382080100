(function($, undefined) {
	'use strict';
	var namespace = 'includeHTML';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var init, create;

		init = function($elem) {
			create($elem);
		};

		// settings.path の html を取得して footer 要素を構築
		create = function($elem) {
			$.ajax({
				url: $elem.data('js-gs18-html-include')
			})
				.done(function(data) {
					var $createdElem = $(data);
					$elem.append($createdElem);
				})
				.fail(function() {
					console.warn('HTML NOT FOUND');
				});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
