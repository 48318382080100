(function($, undefined) {
	'use strict';
	var namespace = 'getFeeds';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				foo: 'bar'
			},
			options
		);

		var init, dateFormat, createObject;

		init = function($elem) {
			var url = $elem.data('js-gs18-feeds');
			var template = _.template($elem.find('[type="text/template"]').text());
			var asPerItems = $elem.data('js-gs18-feeds-per-items');

			$.ajax({
				url: url,
				type: 'get'
			}).done(function(data) {
				// data-js-gs18-feeds-count で表示する件数を指定
				// 指定が無い場合 -1 を slice の第二引数として全件表示
				var count = $elem.data('js-gs18-feeds-count') || -1;
				var items = createObject(data, asPerItems).slice(0, count);
				$elem.append(template({ items: items }));
			});
		};

		// Wed Jun 28 2017 00:00:00 GMT+0900 (JST)
		// => Jun 28, 2017
		dateFormat = function(date) {
			date = ('' + date).split(' ').slice(1, 4);
			date[1] = date[1] + ',';
			return date.join(' ');
		};

		createObject = function(xml, asPerItems) {
			var data = [];
			var $item = $(xml).find('item');

			$item.each(function() {
				var $this = $(this);
				var item = {
					title: $this.find('title').text(),
					link: $this.find('link').text(),
					description: $this.find('description').text()
				};

				var date = new Date(
					$this
						.find('description')
						.next()
						.text()
				);
				date = dateFormat(date);

				var sameDay = _.find(data, function(day) {
					return day.date === date;
				});

				// 同一日付のエントリーがすでにある場合 items にエントリーを追加する
				if (sameDay && !asPerItems) {
					sameDay.items.push(item);
				} else {
					data.push({
						date: date,
						items: [item]
					});
				}
			});

			return data;
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
