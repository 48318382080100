(function($, undefined) {
	'use strict';
	var namespace = 'includeFooter';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				path: '/assets-gs18/include/_footer.html',
				noajax: false, // true にすると ajax を使わず、html 内にすでにある要素を使用する。
				callback: function($footer) {
					// console.log("some callbacks", $footer)
				}
			},
			options
		);

		var init, create, setup, setCurrent, addEvents, didMount;

		init = function($footer) {
			create($footer);
		};

		// TODO: footer の見た目を整える（必要ないかも）
		setup = function($footer) {};

		// TODO: current 位置の表示
		setCurrent = function($footer) {};

		// TODO: 各種イベントの追加
		addEvents = function($footer) {};

		// NOTE: ヘッダー表示後の callback 関数
		// ajax を使っているので DOMContentLoaded のタイミングでは
		// まだノードが構築されていない可能性があるため callback
		// が必要
		didMount = function($footer) {
			settings.callback($footer);
		};

		// settings.path の html を取得して footer 要素を構築
		create = function($footer) {
			var load = function(data) {
				var $createdFooter = data ? $(data) : $footer;

				// 別サイト向け modifier のために HTML中の footer で指定した class を反映する
				$createdFooter.addClass($footer.prop('class'));

				$footer.replaceWith($createdFooter);
				setup($createdFooter);

				didMount($createdFooter);
			};

			if (settings.noajax) {
				load();
			} else {
				$.ajax({
					url: settings.path
				})
					.done(load)
					.fail(function() {
						console.warn('footer NOT FOUND');
					});
			}
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
