(function($, undefined) {
	'use strict';
	var namespace = 'includeHeader';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				enableFlyOut: true,
				enableSetCurrent: true,
				enableResponsiveLayout: true,
				mediaMode: null, // 別サイト向けに mediaMode を強制上書き 'large' || 'small'
				path: '/assets-gs18/include/_header.html',
				noajax: false, // true にすると ajax を使わず、html 内にすでにある要素を使用する。
				regionsPath: '/assets-gs18/include/_regions.html',
				regionsJsonPath:
					'/sites/ls_common/assets-gs18/data/select_a_region_language.json',
				duration: 'fast',
				hidePosTop: 300,
				selectors: {
					header: '[data-js-gs18-header]',
					regions: '[data-js-gs18-region]',
					pulldown: '.gs18-HeaderPulldown',
					globalMenuTrigger: '[data-js-gs18-pulldown-open-global-menu]',
					menuTrigger: '[data-js-gs18-pulldown-open-menu]',
					closeMenuTrigger: '[data-js-gs18-pulldown-close-menu]',
					globalLinkTrigger: '[data-js-gs18-pulldown-open-menu=globalLink]',
					closeGlobalLinkTrigger:
						'[data-js-gs18-pulldown-close-menu=globalLink]',
					submenuTrigger: '[data-js-gs18-pulldown-open-sub-menu]',
					slideMenuTrigger: '[data-js-gs18-pulldown-slide-menu]',
					searchBox: '.gs18-Header__Search',
					searchBoxTrigger: '[data-js-gs18-search-open-box]',
					langMenu: '[data-js-gs18-lang-switch]',
					langMenuList: '.gs18-HeaderLang__List', // CMS反映後に必要になったのでやむを得ず class 指定している
					langStage: '.gs18-HeaderLang__Stage',
					langMenuTrigger: '.gs18-HeaderLang__Lang',
					cookieAlert: '[data-js-gs18-cookie-alert]',
					cookieAlertTrigger: '[data-js-gs18-cookie-alert-trigger]'
				},
				classes: {
					cookieAlert: 'gs18-CookieAlert',
					cookieAlertModifier: 'gs18-Header--HasCookieAlert',
					bodyHasCookieAlert: 'gs18-HasCookieAlert',
					langStageDouble: 'gs18-HeaderLang__Stage--Double',
					langStageMultiple: 'gs18-HeaderLang__Stage--Multiple',
					pullDownSublistOverflow: 'gs18-HeaderPulldown__Sublist--Overflow',
					fog: 'gs18-HeaderFog'
				},
				callback: function($header) {
					// console.log("some callbacks", $header)
				}
			},
			options
		);

		var init,
			hasCookie,
			setCookie,
			create,
			createRegions,
			setup,
			setLang,
			setLangDouble,
			setLangMultiple,
			setFlyout,
			stopFlyout,
			restoreFlyout,
			setCurrent,
			getMediaMode,
			getCurrentPath,
			setCookieAlert,
			addCookieAlertClass,
			removeCookieAlertClass,
			responsiveAriaAttr,
			closeAllMenu,
			resetAllMenu,
			resetGlobalMenu,
			resetGlobalLink,
			resetSubMenu,
			resetLangMenu,
			showFog,
			hideFog,
			closeLangMenu,
			openLangMenu,
			clearLangMenu,
			onToggleLangMenu,
			onToggleGlobalMenu,
			onToggleGlobalLink,
			onCloseGlobalLink,
			onToggleDropdown,
			onCloseDropdown,
			onToggleSearchBox,
			onToggleSubMenu,
			onSlideMenu,
			onClickFog,
			onAgreeCookieAlert,
			addEvents,
			didMount;

		init = function($header) {
			var device = $header.data('js-gs18-header');
			if (device === 'pc') {
				settings.mediaMode = 'large';
			} else if (device === 'sp') {
				settings.mediaMode = 'small';
			}
			if ($header.is('.gs18-Header--HasUtilityLinks')) {
				$('body').addClass('gs18-HasUtilityLinks');
			}
			create($header);
		};

		hasCookie = function(keyword) {
			return !!document.cookie.split(';').filter(function(item) {
				return item.indexOf(keyword + '=') >= 0;
			}).length;
		};

		setCookie = function(keyword, value, maxAgeDay) {
			document.cookie =
				keyword +
				'=' +
				value +
				';path=/;max-age=' +
				60 * 60 * 24 * maxAgeDay +
				';';
		};

		// header の見た目を整える
		// regions の生成を待つと画面構築が遅れるので setup 実行のタイミングでは regions は存在しない。
		setup = function($header) {
			// 検索ページの場合検索機能を表示しない
			if (location.pathname.match('search/search.html')) {
				$header.addClass('has-no-searchbox');
			}

			// aria 系の準備
			$header
				.find(settings.selectors.searchBoxTrigger)
				.attr('aria-expanded', false);

			setLang($header);
		};

		setLang = function($header) {
			var $langMenu = $header.find(settings.selectors.langMenu);
			if (!$langMenu.length) return;

			var langCount = $langMenu.data('js-gs18-lang-switch');

			if (langCount === 2) {
				setLangDouble($langMenu);
			} else {
				setLangMultiple($langMenu);
			}
		};

		setLangDouble = function($langMenu) {
			var $langStage = $langMenu.find(settings.selectors.langStage);
			$langStage.addClass(settings.classes.langStageDouble);
		};

		setLangMultiple = function($langMenu) {
			var $langStage = $langMenu.find(settings.selectors.langStage);
			$langStage.addClass(settings.classes.langStageMultiple);
		};

		setFlyout = function($header) {
			// 本家 GWS 以外は Flyout しない
			if (!settings.enableFlyOut) return;

			var $window = $(window);
			var lastScrollTop;

			var hide = function() {
				$header.addClass('is-hidden');
			};

			var show = function() {
				$header.removeClass('is-hidden');
			};

			var onScroll = _.throttle(function() {
				var scrollTop = $window.scrollTop();

				if (scrollTop > settings.hidePosTop) $header.addClass('is-hidden');
				if (scrollTop < lastScrollTop) $header.removeClass('is-hidden');
				lastScrollTop = scrollTop;
			}, 100);

			$window.on('scroll', onScroll);
			$header.addClass('is-flyout');

			if ($window.scrollTop()) {
				// ページ内リンクなどでスクロール位置が変わるときに header を隠す
				setTimeout(hide, 500);
			}
		};

		// 各種レイヤー表示時は Flyout を止めて window top:0 にスクロールする
		stopFlyout = function($header) {
			// 本家 GWS 以外は Flyout しない
			if (!settings.enableFlyOut) return;

			var $window = $(window);
			var scrollTop = $window.scrollTop();

			if ($header.is('.is-flyout')) {
				$header.data('last-scroll-pos-top', scrollTop);
			}
			$header.removeClass(['is-flyout', 'is-hidden']);
			$window.scrollTop(0);
		};

		// 各種レイヤーを閉じたときは Flyout を止めたときのスクロール位置に戻す
		restoreFlyout = function($header) {
			// 本家 GWS 以外は Flyout しない
			if (!settings.enableFlyOut) return;

			var $window = $(window);
			var scrollTop = $header.data('last-scroll-pos-top');
			$window.scrollTop(scrollTop);
			$header.addClass('is-flyout');

			// onScroll で is-hidden の付与が走るので restoreFlyout 時のみ後から除去
			setTimeout(function() {
				$header.removeClass('is-hidden');
			}, 10);
		};

		setCurrent = function($header) {
			var currentDirectory = getCurrentPath().match(/^\/[^/]*\//);
			// NOTE: noajax のときCMSが header を構築している前提で setCurrent 処理を止める
			if (!settings.enableSetCurrent || !currentDirectory || settings.noajax)
				return;

			var $links = $header.find('.gs18-HeaderNav__Text');
			var pattern = new RegExp('^' + currentDirectory[0]);

			$links.each(function() {
				var $link = $(this);
				var pathname = $link.prop('pathname');
				// a 要素の pathname が currentDirectory に先頭マッチした場合、現在のカテゴリーであると判定する

				if (pattern.test(pathname)) $link.addClass('is-current');
			});
		};

		getMediaMode = function() {
			return settings.mediaMode || window.MEL_SETTINGS.helper.getMediaMode();
		};

		getCurrentPath = window.MEL_SETTINGS.helper.getCurrentPath;

		setCookieAlert = function($header) {
			if (
				hasCookie('MITSUBISHI') ||
				!$header.has(settings.selectors.cookieAlert).length
			)
				return;

			addCookieAlertClass($header);
		};

		addCookieAlertClass = function($header) {
			$('body').addClass(settings.classes.bodyHasCookieAlert);
			$header.addClass(settings.classes.cookieAlertModifier);
		};

		removeCookieAlertClass = function($header) {
			$('body').removeClass(settings.classes.bodyHasCookieAlert);
			$header.removeClass(settings.classes.cookieAlertModifier);
		};

		responsiveAriaAttr = function($header) {
			var $searchBox = $header.find(settings.selectors.searchBox);
			var $searchBoxTrigger = $searchBox.find(
				settings.selectors.searchBoxTrigger
			);
			if (getMediaMode() !== 'large') {
				$searchBoxTrigger.removeAttr('aria-expanded', true); // large 以外のときは searchBoxTrigger の aria-expanded を強制的に true にする
			} else {
				$searchBoxTrigger.attr('aria-expanded', $searchBox.is('.is-open')); // large に戻したときに aria-expanded を元に戻す
			}
		};

		closeAllMenu = function($header) {
			var $trigger = $header.find(settings.selectors.menuTrigger);
			var $pulldown = $header.find(settings.selectors.pulldown);
			var $regionPullDown = $pulldown.filter('.gs18-HeaderPulldown--Region');
			var $searchBox = $header.find(settings.selectors.searchBox);
			var $searchBoxTrigger = $searchBox.find(
				settings.selectors.searchBoxTrigger
			);

			// Region が開いているときだけ止めた flyout を再開する
			if ($regionPullDown.is('.is-open')) {
				restoreFlyout($header);
			}

			$trigger.removeClass('is-open');
			$header.find('.is-nav-open').removeClass('is-nav-open');
			$pulldown.slideUp(settings.duration, function() {
				$pulldown.removeClass('is-open');
				setTimeout(function() {
					// style 属性に display の値が残ると small/large 切替時に不整合が起こるので除去
					$pulldown.css('display', '');
				}, 200);
			});
			$searchBox.removeClass('is-open');
			$searchBoxTrigger.attr('aria-expanded', false);

			closeLangMenu($header);
		};

		resetAllMenu = function($header) {
			$header.find('.is-open').removeClass('is-open');
			$header.find('.is-open-small').removeClass('is-open-small');
			$header.find('.is-nav-open').removeClass('is-nav-open');
			closeLangMenu($header);
		};

		resetGlobalLink = function($header) {
			var $trigger = $header.find(settings.selectors.globalLinkTrigger);
			var $target = $trigger.next();

			$target.removeClass('is-open-small');
			$trigger.removeClass('is-open-small');
			$target.addClass('is-transition-small');

			hideFog();

			restoreFlyout($header);
			setTimeout(function() {
				$target.removeClass('is-transition-small');
			}, 500);
		};

		resetGlobalMenu = function($header) {
			var $trigger = $header.find(settings.selectors.globalMenuTrigger);
			var $targetSearch = $trigger.prev();
			var $targetMenu = $trigger.next();

			closeAllMenu($header);

			$trigger.removeClass('is-open-small');
			$targetSearch.removeClass('is-open-small');
			$targetMenu.removeClass('is-open-small');
		};

		resetSubMenu = function($header) {
			var $targets = $header.find(settings.selectors.submenuTrigger);
			var $triggers = $targets.next();
			$targets.removeClass('is-open');
			$triggers.removeClass('is-open');
		};

		resetLangMenu = function($header) {
			var $langMenu = $header.find(settings.selectors.langMenu);
			$langMenu.removeClass('is-open');
		};

		showFog = function() {
			$('body').addClass(settings.classes.fog);
		};

		hideFog = function() {
			$('body').removeClass(settings.classes.fog);
		};

		closeLangMenu = function($header) {
			var $langMenuList = $header.find(settings.selectors.langMenuList);
			if ($langMenuList.is(':hidden')) return;

			$langMenuList.slideUp('fast', function() {
				clearLangMenu($header);
			});
		};

		openLangMenu = function($header) {
			var $langMenu = $header.find(settings.selectors.langMenu);
			var $langMenuList = $header.find(settings.selectors.langMenuList);

			resetGlobalMenu($header);
			resetGlobalLink($header);

			if (getMediaMode() === 'small') showFog();

			$langMenu.addClass('is-open');
			$langMenuList.hide().slideDown('fast', function() {
				clearLangMenu($header);
			});
		};

		clearLangMenu = function($header) {
			var $langMenu = $header.find(settings.selectors.langMenu);
			var $langMenuList = $header.find(settings.selectors.langMenuList);
			var $trigger = $header.find(settings.selectors.langMenuTrigger);

			if ($langMenuList.is(':hidden')) {
				$langMenu.removeClass('is-open');
				if (getMediaMode() === 'small') hideFog();
			}
			$langMenuList.css('display', '');
			$trigger.blur();
		};

		onToggleLangMenu = function(ev) {
			var $header = ev.data.$header;
			var $langMenu = $header.find(settings.selectors.langMenu);
			var count = $langMenu.data('js-gs18-lang-switch');
			var $langMenuList = $header.find(settings.selectors.langMenuList);

			if (getMediaMode() === 'large' && count === 2) return;

			ev.preventDefault();
			$langMenuList.is(':hidden')
				? openLangMenu($header)
				: closeLangMenu($header);
		};

		onToggleGlobalMenu = function(ev) {
			if (getMediaMode() !== 'small') return this;

			ev.preventDefault();
			var $trigger = $(ev.currentTarget);
			var $targetSearch = $trigger.prev();
			var $targetMenu = $trigger.next();
			var $header = $trigger.parents(settings.selectors.header);

			resetGlobalLink($header);
			resetLangMenu($header);

			if ($trigger.is('.is-open-small')) {
				$trigger.removeClass('is-open-small');
				$targetSearch.removeClass('is-open-small');
				$targetMenu.removeClass('is-open-small');
				restoreFlyout($header);
				setTimeout(function() {
					hideFog();
				}, 500);
			} else {
				$trigger.addClass('is-open-small');
				$targetSearch.addClass('is-open-small');
				$targetMenu.addClass('is-open-small');
				showFog();
				setTimeout(function() {
					stopFlyout($header);
				}, 500);
			}
		};

		// Regions 開閉
		onToggleGlobalLink = function(ev) {
			if (getMediaMode() !== 'small') return this;

			ev.preventDefault();
			var $trigger = $(ev.currentTarget);
			var $target = $trigger.next();
			var $header = $trigger.parents(settings.selectors.header);

			var show = function() {
				$target.addClass('is-open-small');
				$trigger.addClass('is-open-small');
				setTimeout(function() {
					stopFlyout($header);
				}, 500);
				showFog();
			};

			var hide = function() {
				$target.removeClass('is-open-small');
				$trigger.removeClass('is-open-small');
				$target.addClass('is-transition-small');
				setTimeout(function() {
					$target.removeClass('is-transition-small');
					hideFog();
				}, 500);
				restoreFlyout($header);
			};

			resetGlobalMenu($header);
			resetLangMenu($header);
			$trigger.is('.is-open-small') ? hide() : show();
		};

		onCloseGlobalLink = function(ev) {
			if (getMediaMode() !== 'small') return this;

			ev.preventDefault();
			var $target = $(ev.currentTarget).parents(settings.selectors.pulldown);
			var $openButton = $target.siblings(settings.selectors.globalLinkTrigger);
			var $header = $target.parents(settings.selectors.header);

			$openButton.removeClass('is-open-small');
			$target.removeClass('is-open-small');
			$target.addClass('is-transition-small');
			restoreFlyout($header);
			setTimeout(function() {
				$target.removeClass('is-transition-small');
				hideFog();
			}, 500);
		};

		onToggleDropdown = function(ev) {
			var $trigger = $(ev.currentTarget);
			var $pulldown = $trigger.next(settings.selectors.pulldown);
			// 子階層が存在しないか画面幅が large では無い場合は早期 return
			if (!$pulldown.length || getMediaMode() !== 'large') return this;

			ev.preventDefault();
			var duration = settings.duration;
			var $header = $trigger.parents(settings.selectors.header);
			var $headerNav = $header.find('.gs18-HeaderNav');

			var slideUp = function() {
				// Region のときだけ止めた flyout を再開する
				if ($pulldown.is('.gs18-HeaderPulldown--Region')) {
					restoreFlyout($header);
				}
				$pulldown.slideUp(duration, function() {
					$pulldown.removeClass('is-open').css('display', '');
					$trigger.removeClass('is-open');
					$headerNav.removeClass('is-nav-open');
					hideFog();
				});
			};

			var slideDown = function() {
				closeAllMenu(ev.data.$header);
				$headerNav.addClass('is-nav-open');
				showFog();
				$pulldown.slideDown(duration, function() {
					// Region のときだけ flyout を止める
					if ($pulldown.is('.gs18-HeaderPulldown--Region')) {
						stopFlyout($header);
					}
					$trigger.addClass('is-open');
					$pulldown.addClass('is-open').css('display', '');
				});
			};

			$trigger.blur();
			$pulldown.is(':visible') ? slideUp() : slideDown();
		};

		onCloseDropdown = function(ev) {
			if (getMediaMode() !== 'large') return this;

			ev.preventDefault();
			var $pulldown = $(ev.currentTarget).parents(settings.selectors.pulldown);
			var $openbutton = $pulldown.siblings(settings.selectors.menuTrigger);
			var $header = $pulldown.parents(settings.selectors.header);
			var $headerNav = $header.find('.gs18-HeaderNav');

			if ($pulldown.is('.gs18-HeaderPulldown--Region')) {
				restoreFlyout($header);
			}

			$pulldown.slideUp(settings.duration, function() {
				$pulldown.removeClass('is-open').css('display', '');
				$headerNav.removeClass('is-nav-open');
				hideFog();
			});
			$openbutton.removeClass('is-open');
		};

		onToggleSubMenu = function(ev) {
			if (getMediaMode() !== 'large') return this;

			ev.preventDefault();
			var $trigger = $(ev.currentTarget);
			var $target = $trigger.next();
			var $header = $trigger.parents(settings.selectors.header);
			var $list = $target.find('.gs18-HeaderPulldown__List');
			var $items = $target.find('.gs18-HeaderPulldown__ListItem');

			var setScroll = function() {
				// list 内の高さを集計
				var height = 70; // list の上余白
				$items.each(function() {
					height += $items.height();
				});

				// list の高さより list 内のほうが高い場合に --Overflow modifier をつける。
				if (height > $list.height()) {
					$target.addClass(settings.classes.pullDownSublistOverflow);
				} else {
					$target.removeClass(settings.classes.pullDownSublistOverflow);
				}
			};

			if ($target.is('.is-open')) {
				$target.removeClass('is-open');
				$trigger.removeClass('is-open').blur();
			} else {
				resetSubMenu($header);
				$target.addClass('is-open');
				$trigger.addClass('is-open');

				setScroll();
			}
		};

		onToggleSearchBox = function(ev) {
			if (getMediaMode() !== 'large') return this;

			ev.preventDefault();
			var $trigger = $(ev.currentTarget);
			var $searchBox = $trigger.parents(settings.selectors.searchBox);
			var $searchBoxTrigger = $searchBox.find(
				settings.selectors.searchBoxTrigger
			);
			var $headerNav = ev.data.$header.find('.gs18-HeaderNav');
			var $headerInner = ev.data.$header.find('.gs18-Header__Inner');
			var tidyStyle = function() {
				if ($searchBox.is('.is-open')) {
					$searchBox.find(':text').focus();
				} else {
					$searchBox.removeClass('is-opening');
					$headerInner.removeClass('is-search-open');
				}
			};

			$trigger.blur();

			if ($searchBox.is('.is-open')) {
				closeAllMenu(ev.data.$header);
				$searchBox.addClass('is-opening');
				$searchBox.removeClass('is-open');
				$headerNav.removeClass('is-hidden');
				$searchBoxTrigger.attr('aria-expanded', false);

				setTimeout(tidyStyle, 500);
			} else {
				closeAllMenu(ev.data.$header);
				$headerInner.addClass('is-search-open');
				$searchBox.addClass('is-open');
				$headerNav.addClass('is-hidden');
				$searchBoxTrigger.attr('aria-expanded', true);

				setTimeout(tidyStyle, 500);
			}
		};

		// SP用グローバルナビゲーションの横スライド
		onSlideMenu = function(ev) {
			if (getMediaMode() !== 'small') return this;

			ev.preventDefault();
			var $trigger = $(ev.currentTarget);
			var $navBody = $trigger.parents('.gs18-HeaderNav');
			var $childMenu = $trigger.next();
			var $currentMenu = $trigger.parents('.is-current-small').first();
			var $target = $trigger.parents('.gs18-HeaderNav__Menu');

			var state = $trigger.data('js-gs18-pulldown-slide-menu') || 'go';
			var left = Math.abs($target.position().left, 10);
			var step = Math.round(left / $('body').width()); // IE11, Edge でscrollbarの幅が含まれてしまうので Math.round
			var nextStep = (state === 'go' ? 1 : -1) + step;

			var setHeight = function() {
				$navBody.css({ minHeight: $childMenu.height() || null });
			};

			if (state === 'go') {
				// 移動先の子メニューを事前に表示
				$childMenu.addClass('is-current-small');
			} else if (state === 'back') {
				setTimeout(function() {
					// アニメーションを終えてから移動前のメニューを非表示
					$currentMenu.removeClass('is-current-small');
				}, 500);
			}

			// .is-step[0-2] を css で left 移動
			$target.removeClass(['is-step0', 'is-step1', 'is-step2']);
			$target.addClass('is-step' + nextStep);

			setHeight();
		};

		onClickFog = function(ev) {
			if (ev.target !== ev.currentTarget) return;

			var $header = ev.data.$header;
			var $searchBox = $header.find(settings.selectors.searchBox);

			ev.preventDefault();
			if (getMediaMode() === 'large') {
				$header
					.find('.is-open ' + settings.selectors.closeMenuTrigger)
					.trigger('click');
			} else if (getMediaMode() === 'small') {
				closeLangMenu($header);

				$header
					.find('.is-open-small' + settings.selectors.globalMenuTrigger)
					.trigger('click');

				$header
					.find('.is-open-small ' + settings.selectors.closeGlobalLinkTrigger)
					.trigger('click');
			}
		};

		onAgreeCookieAlert = function(ev) {
			ev.preventDefault();
			var $header = ev.data.$header;
			removeCookieAlertClass($header);
			setCookie('MITSUBISHI', 1, 365);
		};

		// TODO: 各種イベントの追加
		addEvents = function($header) {
			$(window).resize(
				_.debounce(
					function() {
						$header.addClass('is-resizing');
					},
					200,
					{
						leading: true,
						trailing: false
					}
				)
			);

			$(window).resize(
				_.debounce(function() {
					$header.removeClass('is-resizing');
					responsiveAriaAttr($header);
					// TODO: remove aria
				}, 200)
			);

			$('body').on('click', { $header: $header }, onClickFog);
			$header
				.find(settings.selectors.cookieAlertTrigger)
				.on('click', { $header: $header }, onAgreeCookieAlert);

			// PC 向け
			$header
				.find(settings.selectors.menuTrigger)
				.on('click', { $header: $header }, onToggleDropdown);

			$header
				.find(settings.selectors.closeMenuTrigger)
				.on('click', { $header: $header }, onCloseDropdown);

			$header
				.find(settings.selectors.submenuTrigger)
				.on('click', { $header: $header }, onToggleSubMenu);

			$header
				.find(settings.selectors.searchBoxTrigger)
				.on('click', { $header: $header }, onToggleSearchBox);

			// SP 向け
			$header
				.find(settings.selectors.globalMenuTrigger)
				.on('click', { $header: $header }, onToggleGlobalMenu);

			$header
				.find(settings.selectors.globalLinkTrigger)
				.on('click', { $header: $header }, onToggleGlobalLink);

			var $temp = $header
				.find(settings.selectors.closeGlobalLinkTrigger)
				.on('click', { $header: $header }, onCloseGlobalLink);

			$header
				.find(settings.selectors.slideMenuTrigger)
				.on('click', { $header: $header }, onSlideMenu);

			// SP/PC 共通
			$header
				.find(settings.selectors.langMenuTrigger)
				.on('click', { $header: $header }, onToggleLangMenu);
		};

		// NOTE: ヘッダー表示後の callback 関数
		// ajax を使っているので DOMContentLoaded のタイミングでは
		// まだノードが構築されていない可能性があるため callback
		// が必要
		didMount = function($header) {
			setCookieAlert($header);
			setCurrent($header);
			setFlyout($header);
			addEvents($header);
			settings.callback($header);
		};

		// settings.path の html を取得して header 要素を構築
		create = function($header) {
			var load = function(data) {
				var $createdHeader = data ? $(data) : $header;
				var $regions = $createdHeader.find(settings.selectors.regions);

				// 別サイト向け modifier のために HTML中の header で指定した class を反映する
				$createdHeader.addClass($header.prop('class'));

				// DOM の構造を深くしない、かつ _header.html の内容を単一のルート要素に限定するために
				// 既存の header を replaceWith で差し替え
				$header.replaceWith($createdHeader);
				setup($createdHeader);

				if ($regions.data('js-gs18-region') === 'json') {
					$regions.includeRegions({
						regionsJsonPath: settings.regionsJsonPath,
						callback: function($createdRegions) {
							$regions.append($createdRegions);
							didMount($createdHeader);
						}
					});
				} else if ($regions.length && settings.regionsPath) {
					// Regions の外部ファイル指定がある場合 Regions を構築
					createRegions($regions).done(function() {
						didMount($createdHeader);
					});
				} else {
					didMount($createdHeader);
				}
			};

			if (settings.noajax) {
				load();
			} else {
				$.ajax({
					url: settings.path
				})
					.done(load)
					.fail(function() {
						console.warn('HEADER NOT FOUND');
					});
			}
		};

		// settings.regionsPath の html を取得して国言語セレクタを構築
		createRegions = function($regions) {
			return $.ajax({
				url: settings.regionsPath
			}).done(function(data) {
				var $createdRegions = $(data);

				// create($header) と構造をあわせるために既存の regions を replaceWith で差し替え
				$regions.replaceWith($createdRegions);
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
