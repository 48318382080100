(function($, undefined) {
	'use strict';
	var namespace = 'setLearnMoreTile';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selectors: {
					target: '[data-js-gs18-learn-more-tile-target]',
					triggerWrapper: '[data-js-gs18-learn-more-tile-trigger-wrapper]',
					trigger: '[data-js-gs18-learn-more-tile-trigger]'
				}
			},
			options
		);

		var init, show, addEvents;

		init = function($elem) {
			var $target = $elem.find(settings.selectors.target);
			var $triggerWrapper = $elem.find(settings.selectors.triggerWrapper);

			addEvents($triggerWrapper, $target);
		};

		show = function(ev) {
			ev.preventDefault();

			var $target = ev.data.$target;
			var $triggerWrapper = ev.data.$triggerWrapper;

			$target.show();
			$target.height(); // 高さを算出することで一旦画面をレンダーする。後続の属性付与で transition を発生させるため。
			$target.removeAttr('data-js-gs18-learn-more-tile-target');
			$triggerWrapper.hide();
		};

		addEvents = function($triggerWrapper, $target) {
			var $trigger = $triggerWrapper.find(settings.selectors.trigger);

			$trigger.on(
				'click',
				{
					$target: $target,
					$triggerWrapper: $triggerWrapper
				},
				show
			);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
