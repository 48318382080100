(function($, undefined) {
	'use strict';
	var namespace = 'includeRegions';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				foo: 'bar',
				regionsJsonPath: '/sites/ls_common/assets-gs18/data/select_a_region_language.json',
				type: 'header',
				callback: function() {}
			},
			options
		);

		var init,
			getResionsType,
			getResionsJSON,
			createRegions,
			removeHiddenLanguage,
			removeHiddenCountry,
			current_pathname,
			templateHeaderRegions,
			templateLocationRegions,
			templateAtWRegions;

		init = function($elem) {
			getResionsJSON(function(data) {
				var $createdRegions = createRegions(
					getResionsType(settings.type),
					data
				);
				settings.callback($createdRegions);
			});
		};

		getResionsType = function(type) {
			var restionsType;
			if (type === 'header') {
				restionsType = 'headerRegions';
			} else if (type === 'locations') {
				restionsType = 'locationRegions';
			} else if (type === 'AtW') {
				restionsType = 'AtWRegions';
			}

			return restionsType;
		};

		getResionsJSON = function(callback) {
			$.ajax({
				url: settings.regionsJsonPath
			})
				.done(function(data) {
					data.title = 'Select a Region/Language';
					data.current_pathname = current_pathname;
					_.each(data.location.region, function(region) {
						_.each(region.country, removeHiddenLanguage);
						removeHiddenCountry(region);
					});
					callback(data);
				})
				.fail(function() {
					console.warn(arguments);
				});
		};

		createRegions = function(type, data) {
			if (type === 'headerRegions') {
				return $(templateHeaderRegions({ data: data }));
			} else if (type === 'locationRegions') {
				return $(templateLocationRegions({ data: data }));
			} else if (type === 'AtWRegions') {
				return $(templateAtWRegions({ data: data }));
			}
		};

		removeHiddenLanguage = function(country) {
			country.lang = _.filter(country.lang, function(lang) {
				var type = getResionsType(settings.type);

				if (type === 'headerRegions') {
					return lang.header_show_status;
				} else if (type === 'locationRegions') {
					return lang.locations_show_status;
				} else if (type === 'AtWRegions') {
					return lang.atw_show_status;
				}
			});
		};

		removeHiddenCountry = function(region) {
			region.country = _.filter(region.country, function(country) {
				return !!country.lang.length;
			});
		};

		// NOTE: /^\/[^/]*\// で第一階層相当のパス（/en/ など）を削除
		current_pathname = window.location.pathname.replace(/^\/[^/]*\//, '');

		// NOTE: header 国言語セレクター用 template
		templateHeaderRegions = _.template(
			[
				'<div class="gs18-HeaderPulldown__Inner">',
				'<div class="gs18-HeaderPulldownRegion">',
				'<div class="gs18-HeaderPulldownRegion__Header">',
				'<p class="gs18-HeaderPulldownRegion__Title"><%= data.title %></p>',
				'</div>',
				'<a class="gs18-HeaderPulldown__Close" data-js-gs18-pulldown-close-menu="globalLink" href="#">',
				'<img src="/assets-gs18/img/icon/Close.svg" alt="×">',
				'</a>',

				'<div class="gs18-Tile">',
				'<div class="gs18-TileItem">',
				'<div class="gs18-TileItem__Head">',
				'<h2 class="gs18-HeadingLv2"><%= data.global.region_name %></h2>',
				'<!-- /.gs18-TileItem__Head --></div>',

				'<div class="gs18-TileItem__Body">',
				'<div class="gs18-LineTableUnit">',
				'<div class="gs18-LineTableUnit__TableCol">',
				'<table class="gs18-LineTable">',
				'<colgroup><col class="gs18-u-Size1of3" />',
				'<col class="gs18-u-Size2of3" />',
				'</colgroup>',
				'<tbody>',
				'<% _.each(data.global.site, function(site){ %>',
				'	<tr>',
				'	<th class="gs18-LineTableBodyTh" scope="row"><%= site.site_name %></th>',
				'	<td class="gs18-LineTableBodyTd">',
				'	<ul class="gs18-LinkList gs18-LinkList--Horizontal gs18-LinkList--Size1of2">',
				'	<% _.each(site.lang, function(lang){ %>',
				'		<li class="gs18-LinkListItem"><a href="<%= lang.url %><%= lang.default_filename %>" class="gs18-Link" lang="<%= lang.lang_id %>"><%= lang.lang_name %></a></li>',
				'	<% }); %>',
				'	</ul>',
				'	</td>',
				'	</tr>',
				'<% }); %>',
				'</tbody>',
				'</table>',
				'<!-- /.gs18-LineTableUnit__TableCol --></div>',
				'<!-- /.gs18-LineTableUnit --></div>',
				'<!-- /.gs18-TileItem__Body --></div>',
				'<!-- /.gs18-TileItem --></div>',
				'</div>',

				'<div class="gs18-Tile">',
				'<% _.each(data.location.region, function(region){ %>',
				'<div class="gs18-TileItem gs18-u-Size1of3">',

				'<div class="gs18-TileItem__Head">',
				'<h2 class="gs18-HeadingLv2"><%= region.region_name %></h2>',
				'<!-- /.gs18-TileItem__Head --></div>',

				'<div class="gs18-TileItem__Body">',
				'<div class="gs18-LineTableUnit">',
				'<div class="gs18-LineTableUnit__TableCol">',
				'<table class="gs18-LineTable">',
				'<colgroup><col class="gs18-u-Size1of3" />',
				'<col class="gs18-u-Size2of3" />',
				'</colgroup>',
				'<tbody>',

				'<% _.each(region.country, function(country){ %>',
				'	<% if(country.header_show_status){ %>',
				'		<tr>',
				'		<th class="gs18-LineTableBodyTh" scope="row"><%= country.country_name %></th>',
				'		<td class="gs18-LineTableBodyTd">',
				'		<ul class="gs18-LinkList gs18-LinkList--Horizontal gs18-LinkList--Size1of2">',
				'		<% _.each(country.lang, function(lang){ %>',
				'			<% if(lang.header_show_status){ %>',
				'				<li class="gs18-LinkListItem"><a href="<%= lang.url %><%= lang.header_default_pathname === null ? data.current_pathname : lang.header_default_pathname %>" class="gs18-Link" lang="<%= lang.lang_id %>"><%= lang.lang_name %></a></li>',
				'			<% }; %>',
				'		<% }); %>',
				'		</ul>',
				'		</td>',
				'		</tr>',
				'	<% }; %>',
				'<% }); %>',

				'</tbody>',
				'</table>',
				'<!-- /.gs18-LineTableUnit__TableCol --></div>',
				'<!-- /.gs18-LineTableUnit --></div>',
				'<!-- /.gs18-TileItem__Body --></div>',
				'<!-- /.gs18-TileItem --></div>',
				'<% }); %>',
				'</div>',

				'</div>',
				'</div>'
			].join('\n')
		);

		// NOTE: about/locations
		templateLocationRegions = _.template(
			[
				'<% _.each(data.location.region, function(region){ %>',
				'<div class="gs18-TileItem gs18-u-Size1of3">',
				'<div class="gs18-TileItem__Head">',
				'<h2 class="gs18-HeadingLv2"><%= region.region_name %></h2>',
				'<!-- /.gs18-TileItem__Head --></div>',
				'<div class="gs18-TileItem__Body">',
				'<div class="gs18-LineTableUnit">',
				'<div class="gs18-LineTableUnit__TableCol">',
				'<table class="gs18-LineTable">',
				'<col class="gs18-u-Size1of3" />',
				'<col class="gs18-u-Size2of3" />',
				'<tbody>',
				'<% _.each(region.country, function(country){ %>',
				'<tr>',
				'<th class="gs18-LineTableBodyTh" scope="row"><%= country.country_name %></th>',
				'<td class="gs18-LineTableBodyTd">',
				'<ul class="gs18-LinkList gs18-LinkList--Horizontal gs18-LinkList--Size1of2">',
				'<% _.each(country.lang, function(lang){ %>',
				'<% if (lang.locations_show_status === true) { %>',
				'<li class="gs18-LinkListItem"><a href="<%= lang.url %><%= lang.locations_default_pathname %>" target="_blank" class="gs18-Link gs18-Link--External" lang="<%= lang.lang_id %>"><img src="/assets-gs18/img/icon/External.svg" alt="(open new window)" class="gs18-Icon" /><%= lang.lang_name %></a></li>',
				'<% } else { %>',
				'<li class="gs18-LinkListItem">NO SHOWN</li>',
				'<% } %>',
				'<% }); %>',
				'</ul>',
				'</td>',
				'</tr>',
				'<% }); %>',
				'</tbody>',
				'</table>',
				'<!-- /.gs18-LineTableUnit__TableCol --></div>',
				'<!-- /.gs18-LineTableUnit --></div>',
				'<!-- /.gs18-TileItem__Body --></div>',
				'<!-- /.gs18-TileItem --></div>',
				'<% }); %>'
			].join('\n')
		);

		// NOTE: en/worldwide
		templateAtWRegions = _.template(
			[
				'<% _.each(data.location.region, function(region){ %>',
				'<div class="gs18-TileItem gs18-u-Size1of3">',
				'<div class="gs18-TileItem__Head">',
				'<h2 class="gs18-HeadingLv2"><%= region.region_name %></h2>',
				'<!-- /.gs18-TileItem__Head --></div>',
				'<div class="gs18-TileItem__Body">',
				'<div class="gs18-LineTableUnit">',
				'<div class="gs18-LineTableUnit__TableCol">',
				'<table class="gs18-LineTable">',
				'<col class="gs18-u-Size1of3" />',
				'<col class="gs18-u-Size2of3" />',
				'<tbody>',
				'<% _.each(region.country, function(country){ %>',
				'<tr>',
				'<th class="gs18-LineTableBodyTh" scope="row"><%= country.country_name %></th>',
				'<td class="gs18-LineTableBodyTd">',
				'<ul class="gs18-LinkList gs18-LinkList--Horizontal gs18-LinkList--Size1of2">',
				'<% _.each(country.lang, function(lang){ %>',
				'<% if (lang.atw_show_status === true) { %>',
				'<li class="gs18-LinkListItem"><a href="<%= lang.url %><%= lang.atw_default_pathname %>" target="_blank" class="gs18-Link gs18-Link--External" lang="<%= lang.lang_id %>"><img src="/assets-gs18/img/icon/External.svg" alt="(open new window)" class="gs18-Icon" /><%= lang.lang_name %></a></li>',
				'<% } else { %>',
				'<li class="gs18-LinkListItem">NO SHOWN</li>',
				'<% } %>',
				'<% }); %>',
				'</ul>',
				'</td>',
				'</tr>',
				'<% }); %>',
				'</tbody>',
				'</table>',
				'<!-- /.gs18-LineTableUnit__TableCol --></div>',
				'<!-- /.gs18-LineTableUnit --></div>',
				'<!-- /.gs18-TileItem__Body --></div>',
				'<!-- /.gs18-TileItem --></div>',
				'<% }); %>'
			].join('\n')
		);

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
