(function($, undefined) {
	'use strict';
	var namespace = 'jsYouTube';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				this: this,
				youtube_settings: {},
				maxResults: 4,
				order: 'date'
			},
			options
		);

		this.each(function() {
			var init, api, search, getPlayList, createNode;
			var $elem = $(this);
			var source = $elem.data('video-source');
			var youtube_settings = settings.youtube_settings[source];
			var key = youtube_settings.youtube_api_key;
			var channel_id = youtube_settings.youtube_channel_id;
			var playlist_id = youtube_settings.youtube_playlist_id;

			init = function() {
				api = playlist_id ? getPlayList : search;
				api().done(createNode);
			};

			search = function() {
				return $.ajax({
					type: 'get',
					url: 'https://www.googleapis.com/youtube/v3/search',
					dataType: 'json',
					data: {
						part: 'snippet',
						channelId: channel_id,
						order: settings.order,
						maxResults: settings.maxResults,
						type: 'video',
						key: key
					},
					context: this
				});
			};

			getPlayList = function() {
				return $.ajax({
					type: 'get',
					url: 'https://www.googleapis.com/youtube/v3/playlistItems',
					dataType: 'json',
					data: {
						part: 'snippet',
						playlistId: playlist_id,
						order: settings.order,
						maxResults: settings.maxResults,
						type: 'video',
						key: key
					},
					context: this
				});
			};

			createNode = function(response) {
				response.items.forEach(function(item) {
					var videoURL =
						'https://www.youtube.com/watch?v=' +
						(item.id.videoId || item.snippet.resourceId.videoId);
					var img = item.snippet.thumbnails.high.url;
					var title = item.snippet.title;
					$elem.append(
						[
							'<li class="gs18-YouTube__Item">',
							'<a class="gs18-YouTube__Img" href=' +
								videoURL +
								' target="_blank">',
							'<img src=' + img + '>',
							'</a>',
							'<p class="gs18-YouTube__Title">',
							'<a href=' + videoURL + ' target="_blank">' + title + '</a>',
							'</p>',
							'</li>'
						].join('')
					);
				});
			};

			init();
		});

		return this;
	};
})(window.jQuery3 || jQuery);
