(function($, undefined) {
	'use strict';
	var namespace = 'goTopScroll';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				this: this
			},
			options
		);

		this.each(function() {
			var href = settings.this.attr('href');
			var $target = $('html').find(href === '#' ? 'body' : href);
			var position = $target.offset().top;
			$('html, body').animate({ scrollTop: position }, 500, 'swing');
			return false;
		});

		return this;
	};
})(window.jQuery3 || jQuery);
