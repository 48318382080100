// jquery.cnt.accordion.jsの読み込み必須
// easingの値の設定次第では、jquery.easing.jsの読み込みが必要

(function($, undefined) {
	'use strict';
	var namespace = 'setCustomAccordion';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selectors: {
					trigger: '[data-js-gs18-accordion-trigger]',
					content: '[data-js-gs18-accordion-content]'
				},
				toggleContent: function() {
					return $(this).next(settings.selectors.content);
				},
				duration: 200,
				easing: 'swing',
				triggerClass: {
					opened: 'is-open',
					closed: 'is-close'
				},
				defaultStatus: '',
				accessibilityFlag: true
			},
			options
		);

		var init = function($elem) {
			$elem.find(settings.selectors.trigger).customAccordion({
				toggleContent: settings.toggleContent,
				duration: settings.duration,
				easing: settings.easing,
				triggerClass: settings.triggerClass,
				defaultStatus: settings.defaultStatus,
				accessibilityFlag: settings.accessibilityFlag
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
