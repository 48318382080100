(function($, undefined) {
	'use strict';
	var namespace = 'includeLocalNav';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				path: '/assets-gs18/include/_local-nav.html',
				callback: function($localNav) {
					// console.log("some callbacks", $localNav)
				}
			},
			options
		);

		var init, create, setup, setCurrent, setNavHeight, addEvents, didMount;

		init = function($localNav) {
			create($localNav);
		};

		// TODO: localNav の見た目を整える
		// SP/PCの考慮が必要
		setup = function($localNav) {
			setNavHeight($localNav);
		};

		// TODO: current 位置の表示
		setCurrent = function($localNav) {};

		setNavHeight = function($localNav) {
			var $localNavList = $localNav.find('.gs18-LocalNavList');
			var localNavHeights = [];
			$localNavList.each(function(i) {
				localNavHeights[i] = $(this)
					.css('height', '')
					.height();
			});
			$localNavList.height(Math.max.apply(null, localNavHeights));
		};

		addEvents = function($localNav) {
			// NOTE: ES Modules が使えないので lodash.throttle を使用
			// 今のところ throttle にしか lodash を使ってないので小さいライブラリに変えたほうが良い
			$(window).on(
				'resize',
				_.throttle(function() {
					setNavHeight($localNav);
				}, 200)
			);
		};

		// NOTE: ヘッダー表示後の callback 関数
		// ajax を使っているので DOMContentLoaded のタイミングでは
		// まだノードが構築されていない可能性があるため callback
		// が必要
		didMount = function($localNav) {
			$localNav.createLocalNavDepth({
				local_nav_json_path: settings.local_nav_json_path,
				callback: function() {
					setNavHeight($localNav);
				}
			});

			addEvents($localNav);
			settings.callback($localNav);
		};

		// settings.path の html を取得して localNav 要素を構築
		create = function($localNav) {
			$.ajax({
				url: settings.path
			})
				.done(function(data) {
					// DOM の構造を深くしない、かつ _local-nav.html の内容を単一のルート要素に限定するために
					// 既存の localNav を replaceWith で差し替え
					var $createdLocalNav = $(data);

					$localNav.replaceWith($createdLocalNav);
					setup($createdLocalNav);

					didMount($createdLocalNav);
				})
				.fail(function() {
					console.warn('localNav NOT FOUND');
				});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
