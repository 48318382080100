(function($, undefined) {
	'use strict';
	var namespace = 'pulldownLink';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var init, onChange;

		init = function($elem) {
			$elem.on('change', onChange);
		};

		onChange = function(ev) {
			var $select = $(ev.currentTarget);
			var path = $select.val();
			var windowName = $select.find(':selected').data('target');
			// windowName が指定されていて window.open できるときは別タブで開く
			if (
				path &&
				((windowName && !window.open(path, windowName)) || !windowName)
			) {
				location.href = path;
			}
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
