(function($, undefined) {
	'use strict';
	var namespace = 'toggleGA';
	var hostname = window.location.hostname;
	var gaPropertyIdList = {
		'gb.mitsubishielectric.com': 'UA-59055488-18',
		'test-gb.mitsubishielectric.com': 'UA-59055488-18',
		localhost: 'UA-TESTPROPERTY-1'
	};
	var gaPropertyId = gaPropertyIdList[hostname];
	var disableGaPropertyId = 'ga-disable-' + gaPropertyId;
	var cookieValue, getCookie, setCookie, deleteCookie;

	getCookie = function(keyword) {
		var targetCookie =
			document.cookie.split(';').filter(function(item) {
				return item.indexOf(keyword + '=') >= 0;
			})[0] || '';
		return targetCookie.replace(keyword + '=', '');
	};

	setCookie = function(keyword, value, maxAgeDay) {
		document.cookie =
			keyword +
			'=' +
			value +
			';path=/;max-age=' +
			60 * 60 * 24 * maxAgeDay +
			';';
	};

	deleteCookie = function(keyword) {
		setCookie(keyword, '', 0);
	};

	// NOTE: ここからページ初期化時の GA 処理 （全ページ共通）
	cookieValue = getCookie('ME_DISABLE_GA_HOST').replace(/^\s/g, '');

	if (cookieValue === hostname && gaPropertyId) {
		window[disableGaPropertyId] = true;
		// eslint-disable-next-line no-console
		console.warn('Exec `window["' + disableGaPropertyId + '"] = true;`');
	}

	// ここからゲートウェイページでのUI操作
	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selector: {
					radio: '[data-js-gs18-toggle-ga-radio]',
					trigger: '[data-js-gs18-toggle-ga-trigger]'
				}
			},
			options
		);

		var init = function($elem) {
			// eslint-disable-next-line no-alert
			if (!gaPropertyId) return alert('GA ID NOT FOUND');

			var $radio = $elem.find(settings.selector.radio);
			var $trigger = $(settings.selector.trigger); // HTML構造上コンテナ外にボタンがあるため

			$trigger.on('click', function() {
				if ($radio.filter(':checked').val() === 'disable_ga') {
					setCookie('ME_DISABLE_GA_HOST', window.location.hostname, 365);
				} else {
					deleteCookie('ME_DISABLE_GA_HOST');
				}

				// NOTE: Copy from /en/cookie-gateway/js/cookie_policy.js
				var getReferrer = function() {
					// var referrer = document.referrer;
					// if (!referrer) {
					var referrer = window.location.href;
					if (referrer.indexOf('http') !== 0) {
						referrer =
							window.location.href
								.split('/')
								.slice(0, 4)
								.join('/') + '/';
					}
					return referrer;
				};

				setCookie('MITSUBISHI2', 1, 365);
				if (window.location.href === getReferrer()) {
					window.location.reload(true);
				} else {
					window.location.href = getReferrer();
				}
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
