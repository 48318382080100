(function($, undefined) {
	'use strict';
	var namespace = 'jsExample';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				foo: 'bar'
			},
			options
		);

		var init,
			someFunc,
			init = function($elem) {
				someFunc(
					$elem.prop('tagName'),
					'.' + $elem.prop('className').replace(/ /g, '.')
				);
			};

		// サンプル用：取得した要素の tagName. className, 引数 foo を console.log に表示
		someFunc = function(tagName, className) {
			console.log(tagName + className + ': ' + settings.foo);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
