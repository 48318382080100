// jquery.cnt.vichanger.jsの読み込み必須
// easingの値の設定次第では、jquery.easing.jsの読み込み必須
(function($, undefined) {
	'use strict';
	var namespace = 'setViChanger';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selectors: {
					mainViewArea: '[data-js-gs18-news-slide-body]',
					mainWrapper: '[data-js-gs18-news-slide-main]',
					naviWrapper: '[data-js-gs18-news-slide-nav]',
					prevBtn: '[data-js-gs18-news-slide-prev]',
					nextBtn: '[data-js-gs18-news-slide-next]',
					pauseBtn: null,
					imgCol: '[data-js-gs18-news-slide-imgcol]'
				},
				changeType: 'slide',
				naviActiveClassName: 'is-active',
				duration: 600,
				easing: 'swing',
				auto: null,
				startIndex: 0,
				swipeFlag: true,
				liquidLayoutFlag: true,
				accessibilityFlag: true
			},
			options
		);

		var viOptions = {
			mainViewAreaSelector: function() {
				return $(this).find(settings.selectors.mainViewArea);
			},
			mainWrapperSelector: function() {
				return $(this).find(settings.selectors.mainWrapper);
			},
			naviWrapperSelector: function() {
				return $(this).find(settings.selectors.naviWrapper);
			},
			prevBtnSelector: function() {
				return $(this).find(settings.selectors.prevBtn);
			},
			nextBtnSelector: function() {
				return $(this).find(settings.selectors.nextBtn);
			},
			pauseBtnSelector: function() {
				return $(this).find(settings.selectors.pauseBtn);
			},
			changeType: settings.changeType,
			naviActiveClassName: settings.naviActiveClassName,
			pauseActiveClassName: settings.pauseActiveClassName,
			duration: settings.duration,
			easing: settings.easing,
			auto: settings.auto,
			startIndex: settings.startIndex,
			swipeFlag: settings.swipeFlag,
			liquidLayoutFlag: settings.liquidLayoutFlag,
			accessibilityFlag: settings.accessibilityFlag
		};

		var init, setImageHeight;

		init = function($elem) {
			if (settings.isSetImageHeight) {
				$(window).on('load', function() {
					setImageHeight($elem);
				});
			}

			$elem.vichanger(viOptions);
		};

		// _setImageHeight関数：画像の高さ揃え
		setImageHeight = function($viArea) {
			$viArea.each(function() {
				var $this = $(this);
				var $imgCol = $this.find(settings.selectors.imgCol);
				var $imgColImg = $imgCol.find('img');
				var imgHeights = [];

				$imgColImg.each(function(i) {
					imgHeights[i] = $(this)
						.css('height', '')
						.height();
				});
				$imgCol.height(Math.max.apply(null, imgHeights));
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
