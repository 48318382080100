(function($, undefined) {
	'use strict';
	var namespace = 'setRelatedLinks';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				classes: {
					hideDotsLarge: 'gs18-RelatedLinkSlide--HideDotsLarge',
					dots: 'gs18-RelatedLinkSlide__Dots'
				},
				selectors: {
					slideBody: '[data-js-gs18-related-slide-main]',
					prevArrow: '[data-js-gs18-related-slide-prev]',
					nextArrow: '[data-js-gs18-related-slide-next]',
					dots: '[data-js-gs18-related-slide-nav]'
				}
			},
			options
		);

		this.each(function() {
			var $slide = $(this);
			var $slideBody = $slide.find(settings.selectors.slideBody);
			var $prevArrow = $slide.find(settings.selectors.prevArrow);
			var $nextArrow = $slide.find(settings.selectors.nextArrow);
			var $dots = $slide.find(settings.selectors.dots);

			if ($slideBody.children().length <= 3)
				$slide.addClass(settings.classes.hideDotsLarge);

			$slideBody.slick({
				prevArrow: $prevArrow,
				nextArrow: $nextArrow,
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: $slideBody.children().length > 3, // NOTE: Large のときに gs18-RelatedLinkSlide__Item が3個以下の場合 dots を表示しない
				dotsClass: settings.classes.dots,
				appendDots: $dots,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true
						}
					}
				]
			});
		});

		return this;
	};
})(window.jQuery3 || jQuery);
