(function($, undefined) {
	'use strict';
	var namespace = 'setLearnMoreNews';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var dataDeferredImg = 'data-js-gs18-learn-more-news-deferred-img';

		var settings = $.extend(
			{
				partialItemsLength: 10,
				attrs: {
					deferredImg: dataDeferredImg
				},
				selectors: {
					source: '[data-js-gs18-learn-more-news-source]',
					newsList: '[data-js-gs18-learn-more-news-contents]',
					trigger: '[data-js-gs18-learn-more-news-trigger]',
					triggerContainer: '[data-js-gs18-learn-more-news-trigger-container]',
					deferredImg: '[' + dataDeferredImg + ']'
				}
			},
			options
		);

		var init,
			getNewsString,
			replaceImg2Span,
			createNewsItemNodes,
			loadImg,
			setList,
			onAppendNews,
			addEvents;

		init = function($elem) {
			var $source = $elem.find(settings.selectors.source);
			var $newsItems = createNewsItemNodes(getNewsString($source));
			var $list = $elem.find(settings.selectors.newsList);
			var $trigger = $elem.find(settings.selectors.trigger);
			var $triggerContainer = $elem.find(settings.selectors.triggerContainer);

			setList($list, $newsItems, $triggerContainer);
			addEvents($trigger, $triggerContainer, $list, $newsItems);
		};

		// script 要素から HTML を取得
		getNewsString = function($source) {
			return '' + $source.html();
		};

		// <img /> を正規表現で <span /> に差し替えて初期化時に画像取得を抑制
		// .gs18-Icon も差し替えてしまうが、コードの処理が複雑になる一方でメリットが少ないのでそのまま
		replaceImg2Span = function(htmlString) {
			return htmlString.replace(
				/<img([^>]*[^/])\/*>/g,
				'<span ' + settings.attrs.deferredImg + ' $1></span>'
			);
		};

		// HTMLからノードを生成し、ニュースの li を返す
		createNewsItemNodes = function(htmlString) {
			var $temp = $('<div />').append(replaceImg2Span(htmlString));
			return $temp.find(settings.selectors.newsList).children();
		};

		// img の遅延ロード
		// 事前に span[data-js-gs18-learn-more-news-deferred-img] に置換した要素を img に戻す
		loadImg = function($target) {
			var $newImg = $('<img />');
			$($target[0].attributes).each(function() {
				$newImg.attr(this.name, this.value);
			});
			$target.replaceWith($newImg);
		};

		// List の初期化処理
		setList = function($target, $newsItems, $triggerContainer) {
			// 残りのニュースが0件のときにボタンを隠す
			if ($newsItems.length === 0) $triggerContainer.hide();
		};

		// ボタン押下時に partialItemsLength の数だけニュースを追加し、残りが0件になったらボタンを隠す
		onAppendNews = function(ev) {
			ev.preventDefault();
			var $list = ev.data.$list;
			var $newsItems = ev.data.$newsItems;
			var $trigger = ev.data.$trigger;
			var $triggerContainer = ev.data.$triggerContainer;
			var $partialItems = $($newsItems.splice(0, settings.partialItemsLength));

			$partialItems.find(settings.selectors.deferredImg).each(function() {
				loadImg($(this));
			});

			$partialItems.css('opacity', 0);
			$list.append($partialItems);
			$partialItems.animate({ opacity: 1 });

			if ($newsItems.length === 0) $triggerContainer.hide();
		};

		addEvents = function($trigger, $triggerContainer, $list, $newsItems) {
			$trigger.on(
				'click',
				{
					$trigger: $trigger,
					$triggerContainer: $triggerContainer,
					$list: $list,
					$newsItems: $newsItems
				},
				onAppendNews
			);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
