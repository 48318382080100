(function($, undefined) {
	'use strict';
	var namespace = 'setModalTH';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				contentPath: '',
				selectors: {
					bg: '[data-js-gs18-modal-bg]',
					body: '[data-js-gs18-modal-body]',
					closeButton: '[data-js-gs18-modal-close]'
				},
				beforeHideDialog: function() {}
			},
			options
		);

		var init,
			createDialog,
			loadContent,
			setPos,
			hideDialog,
			showDialog,
			addEvents;

		init = function($elem) {
			var $dialog = createDialog($elem);
			if (settings.contentPath) {
				loadContent(settings.contentPath, $dialog);
			}

			$(window).on('resize', function() {
				setPos($dialog);
			});
		};

		createDialog = function($elem) {
			if (!$elem.is('body')) return $elem;

			var $fog = $('<div class="gs18-ModalBg" data-js-gs18-modal-bg="" />');
			var $dialog = $(
				[
					'<div class="gs18-Modal" data-js-gs18-modal="">',
					'<button data-js-gs18-modal-close="" class="gs18-Modal__Button">Cancel</button>',
					'</div>'
				].join('\n')
			);

			$elem.append($fog);
			$elem.append($dialog);

			return $dialog;
		};

		loadContent = function(path, $dialog) {
			$.ajax({
				url: path
			})
				.done(function(data) {
					var $bg, $body, $closeButton;
					$dialog.find(settings.selectors.body).remove(); // 前回開いたダイアログコンテンツを除去
					$dialog.prepend(data);

					$bg = $dialog.prev(settings.selectors.bg);
					$body = $dialog.find(settings.selectors.body);
					$closeButton = $dialog.find(settings.selectors.closeButton);

					// モーダル内の画像ロード完了後にモーダル表示
					var $img, imgPreloaderCnt, imgPreloaderMaxCnt;
					$img = $dialog.find('img');
					imgPreloaderCnt = 0;
					imgPreloaderMaxCnt = $img.length;

					$img.each(function() {
						var imgPreloader = new Image();
						imgPreloader.onload = function() {
							++imgPreloaderCnt;
							if (imgPreloaderCnt >= imgPreloaderMaxCnt) {
								showDialog($bg, $dialog);
								addEvents($bg, $dialog, $closeButton);
							}
						};
						imgPreloader.src = $(this).attr('src');
					});
				})
				.fail(function() {
					console.warn('CONTENTS NOT FOUND');
				});
		};

		setPos = function($dialog) {
			var $body = $dialog.find(settings.selectors.body).css('height', '');
			var $closeButton = $dialog.find(settings.selectors.closeButton).last();
			var windowHeight = $(window).height();
			var dialogHeight = $dialog.height();
			// var dialogDefaultMargin = parseInt($dialog.css('left'), 10);
			var dialogDefaultMargin = 0;
			var dialogTopMargin = parseInt($dialog.css('margin-top'), 10);
			var closeButtonRight = parseInt($dialog.css('right'), 10);
			var bodyOffset = $body.offset();
			var diff = windowHeight - dialogHeight - dialogTopMargin;
			var pos = diff / 2;

			if (diff < dialogDefaultMargin * 2) {
				$body.height(windowHeight - dialogTopMargin - dialogDefaultMargin * 2);
				pos = pos + dialogDefaultMargin;
			}

			$dialog.css('top', pos > dialogDefaultMargin ? pos : dialogDefaultMargin);
			$closeButton.css('margin-right', bodyOffset.left - closeButtonRight);
		};

		showDialog = function($bg, $dialog) {
			$dialog.attr('tabIndex', 0);
			$bg.fadeIn();
			$dialog.fadeIn();
			setTimeout(function() {
				setPos($dialog);
				$dialog.focus();
			}, 100);
		};

		hideDialog = function($bg, $dialog) {
			// ページ表示初期にダイアログを出した場合はフォーカスを body に戻す。
			if (settings.contentPath) {
				$('body')
					.attr('tabIndex', 0)
					.focus();
			}
			settings.beforeHideDialog($bg, $dialog);
			$bg.fadeOut();
			$dialog.fadeOut();
		};

		addEvents = function($bg, $dialog, $closeButton) {
			$bg.on('click', function(ev) {
				ev.preventDefault();
				hideDialog($bg, $dialog);
			});

			$closeButton.on('click', function(ev) {
				ev.preventDefault();
				hideDialog($bg, $dialog);
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
