(function($, undefined) {
	'use strict';
	var namespace = 'microMacro';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				classes: {
					open: 'is-open',
					rootOpen: 'is-tile-open'
				},
				selectors: {
					tile: '.gs18-MicroMacro__Item'
				}
			},
			options
		);

		var init, addEvents;

		init = function($elem) {
			addEvents($elem);
		};

		addEvents = function($elem) {
			var $tile = $elem.find(settings.selectors.tile);

			$tile.on('click keydown', function(ev) {
				if ($(ev.target).is('a')) return;
				if (ev.keyCode && ev.keyCode !== 13) return;

				ev.preventDefault();

				var open, close;
				var $currentTile = $(ev.currentTarget);

				open = function() {
					$elem.addClass(settings.classes.rootOpen);
					$tile.removeClass(settings.classes.open);
					$currentTile.addClass(settings.classes.open);
				};

				close = function() {
					$elem.removeClass(settings.classes.rootOpen);
					$currentTile.removeClass(settings.classes.open);
					$currentTile.blur();
				};

				$currentTile.is('.' + settings.classes.open) ? close() : open();
			});
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
