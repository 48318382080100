// via. https://necomesi.jp/blog/tsmd/posts/110
// by https://twitter.com/_tsmd
// Under License CC0 https://twitter.com/_tsmd/status/975615931547136000
(function($, undefined) {
	'use strict';
	var namespace = 'setResponsiveTable';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var $window = $(window);

		var init = function($elem) {
			var $table = $elem.find('>table');
			var dir = $('html').attr('dir');
			var transformOrigin = dir === 'rtl' ? '100% 0' : '0 0';
			var onResize = function() {
				var width = $table.outerWidth();
				var height = $table.outerHeight();
				var $parent = $elem.parent();
				var containerWidth = $parent.width();
				var ratio = containerWidth / width;
				if (ratio < 1) {
					$elem.height(height * ratio);
					$table.css('transform', 'scale(' + ratio + ')');
				} else {
					$elem.height('');
					$table.css('transform', '');
				}
			};
			$table.css('transformOrigin', transformOrigin);
			$window.on('resize', onResize);
			onResize();
		};

		this.each(function() {
			console.log(this);
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
