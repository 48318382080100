// jquery.cnt.localscroll.jsの読み込み必須
// easingの値の設定次第では、jquery.easing.jsの読み込み必須
(function($, undefined) {
	'use strict';
	var namespace = 'setLocalSmoothScroll';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				duration: 200
			},
			options
		);

		var init = function($elem) {
			$elem.find('a[href^="#"]').localSmoothScroll(settings);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
