(function($, undefined) {
	'use strict';
	var namespace = 'jsFacebookResize';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				this: this,
				timer: null
			},
			options
		);

		this.each(function() {
			var htmlStr = $(this).html();
			$(window).on('resize', function() {
				clearTimeout(settings.timer);
				settings.timer = setTimeout(function() {
					$(this).html(htmlStr);
					window.FB.XFBML.parse();
				}, 100);
			});
		});

		return this;
	};
})(window.jQuery3 || jQuery);
