(function($, undefined) {
	'use strict';
	var namespace = 'tidyStaticLocalNav';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				foo: 'bar'
			},
			options
		);

		var init, setNavHeight, addEvents;

		init = function($localNav) {
			setNavHeight($localNav);
			addEvents($localNav);
		};

		setNavHeight = function($localNav) {
			var $localNavList = $localNav.find('.gs18-LocalNavList');
			var localNavHeights = [];
			$localNavList.each(function(i) {
				localNavHeights[i] = $(this)
					.css('height', '')
					.height();
			});
			$localNavList.height(Math.max.apply(null, localNavHeights));
		};

		addEvents = function($localNav) {
			$(window).on(
				'resize',
				_.throttle(function() {
					setNavHeight($localNav);
				}, 200)
			);
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
