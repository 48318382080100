(function($, undefined) {
	'use strict';
	var namespace = 'fileTypeBlank';

	$.fn[namespace] = function(filetype) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				this: this,
				filetype: filetype
			},
			filetype
		);

		this.each(function() {
			$(this)
				.find('a[href*=".' + settings.filetype + '"]')
				.attr('target', '_blank');
		});

		return this;
	};
})(window.jQuery3 || jQuery);
