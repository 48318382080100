(function($, undefined) {
	'use strict';
	var namespace = 'adjustCaptionWidth';

	$.fn[namespace] = function(options) {
		if (!this.length) return false;

		var settings = $.extend(
			{
				selectors: {
					ref: '[data-js-gs18-adjust-caption-width-ref]',
					target: '[data-js-gs18-adjust-caption-width-target]'
				}
			},
			options
		);

		var init, getMediaMode, addEvents, initCapWidth, setCapWidth, resetStyle;

		init = function($adjustCapWrapper) {
			addEvents($adjustCapWrapper);
		};

		getMediaMode = function() {
			return window.MEL_SETTINGS.helper.getMediaMode();
		};

		addEvents = function($adjustCapWrapper) {
			var throttle = _.throttle(function() {
				initCapWidth($adjustCapWrapper);
			}, 200);

			$(window).on('load resize', throttle);
		};

		initCapWidth = function($adjustCapWrapper) {
			if (getMediaMode() === 'large') {
				setCapWidth($adjustCapWrapper);
			} else {
				resetStyle($adjustCapWrapper);
			}
		};

		// settings.selectors.targetの横幅をsettings.selectors.refの横幅に揃える
		setCapWidth = function($adjustCapWrapper) {
			var $ref = $adjustCapWrapper.find(settings.selectors.ref);
			var $target = $adjustCapWrapper.find(settings.selectors.target);
			var refWidth = $ref.outerWidth();

			$target.css('max-width', refWidth + 'px');
		};

		// style属性削除
		resetStyle = function($adjustCapWrapper) {
			var $target = $adjustCapWrapper.find(settings.selectors.target);
			$target.attr('style', '');
		};

		this.each(function() {
			init($(this));
		});

		return this;
	};
})(window.jQuery3 || jQuery);
